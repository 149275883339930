.main {
  margin-top: 3rem;
  min-height: calc(100 * var(--vh) - 9.5rem);
}

.subtitle {
  color: gray;
  font-size: 14px;
  font-style: italic;
}

.input {
  min-height: 10rem;
  margin: 0;
}

.interface {
  margin-top: 1rem;
  padding: 1.5rem 0 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
}

.slider {
  margin: 0;
  height: 10px;
}

#conversion-result {
  padding: 6px 10px;
  border: 1px solid lightgray;
  min-height: 10rem;
  margin-top: 1.5rem;
}

.btn,
.btn:focus {
  background-color: #084887;
  border-color: #084887;
  color: white;
  margin: 0 !important;
  opacity: 1;
}
.btn:hover {
  background-color: #0077b6;
  border-color: #0077b6;
  color: white;
}
.btn:active {
  background-color: #0077b6;
  border-color: #0077b6;
  color: white;
}

.btn[disabled] {
  background-color: #084887;
  border-color: #084887;
  color: white;
  opacity: 0.5;
}

.slider {
  -webkit-appearance: none;
  background: white;
  border: 1px solid #084887;
  border-radius: 5px;
  outline: none;
  opacity: 0.9;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #084887;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #084887;
  cursor: pointer;
}

footer {
  text-align: center;
  padding: 2rem 1rem 0.5rem 1rem;
}

.funding {
  color: gray;
}

@media screen and (max-width: 768px) {
  .main {
    margin-top: 1rem;
  }

  h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1.5rem;
  }
}